import $ from 'jquery';

class Header {
	constructor() {
		this.header = $('.header');
		this.navToggle = $('.nav__toggle');
		this.nav = $('.nav__menu:not(.nav__menu--social)');
		this.navLink = $('.nav__link');
		this.body = $('body');
		this.navItem = $('.nav__item');
	}

	init() {
		//if someone hover over nav link
		$(this.navLink).each((i, el) => {
			$(el)
				.on('mouseenter', () => {
					if ($(el).parent().hasClass('hasSub')) {
						this.nav.addClass('nav__menu--hovered');
					}
				})
				.on('mouseleave', () => {
					$(el)
						.next()
						.on('mouseenter', () => {
							this.nav.addClass('nav__menu--hovered');
						})
						.on('mouseleave', () => {
							this.nav.removeClass('nav__menu--hovered');
						});
				});
		});

		this.navToggle.on('click', () => {
			if (!this.isOpen()) {
				this.header.addClass('header--open');
				this.nav.fadeIn();
			} else {
				this.header.removeClass('header--open');
				this.nav.fadeOut();
			}
		});

		if ($('.hero-slider').length) {
			$(this.nav).addClass('nav__menu--white');
		} else if ($('.template__header').length) {
			$(this.nav).addClass('nav__menu--white');
		} else if ($('.initiative-hero').length) {
			$(this.nav).addClass('nav__menu--white');
		}

		if ($('.hero-collage').length) {
			$('.nav__logo').css('opacity', '0');
		}
	}

	//toggle nav
	toggleNav() {
		this.body.toggleClass('navOpen');
		this.nav.slideToggle();
	}

	//check if you window getter than 1;
	isSticky() {
		var windowPos = $(window).scrollTop(),
			header = $(this.header),
			getHeaderHeight = header.outerHeight(),
			lastPos = 0;

		$(header).css('top', '0px');

		$(window).on('scroll resize', function () {
			var wScroll = $(window).scrollTop();
			if (wScroll > 100) {
				header.addClass('header--sticky').css('top', 0);
				if (wScroll > lastPos) {
					header.css('top', '-' + getHeaderHeight + 'px');
				}
				lastPos = wScroll;
			} else {
				header.removeClass('header--sticky').css('top', '0px');
			}
		});
		console.log('Sticky Header');
	}

	//added background color to header
	isFilled() {
		var windowPos = $(window).scrollTop(),
			header = $(this.header),
			getHeaderHeight = header.outerHeight(),
			lastPos = 0;

		$(window).on('scroll resize', function () {
			var wScroll = $(window).scrollTop();
			if (wScroll > 0) {
				header.addClass('header--filled');
			} else {
				header.removeClass('header--filled');
			}
		});
	}

	//is active mobile nav
	isOpen() {
		return this.header.hasClass('header--open');
	}
}

export default Header;
