import $ from 'jquery';

export default class Popup {
  constructor(el, data) {
    this.popup = $(el);
    this.isOpen = false;
    this.data = data;
  }

  init() {
    console.log('>> popup - loaded');
    const target = $('.popup-anchor');

    this.popup.css({ display: 'none' });

    target.on('click', e => {
      if (!this.isOpen) this.open(e);
    });

    this.popup.on('click', () => {
      this.popup.fadeOut(300, () => {
        this.isOpen = false;
        console.log('>> popup - close');
      });
    });
  }

  open(e) {
    const holder = this.popup.find('.popup__holder');
    holder.empty();
    const id = $(e.target).attr('id');
    if (id) {
      this.data.forEach(val => {
        holder.append(
          `<div class="pricing">
            <div class="pricing__name">${val.unitName}</div>
            <div class="pricing__info">${val.generalInfo}</div>
            <div class="pricing__price">${val.price}</div>
           </div>`
        );
      });
      holder.css({ padding: '140px 270px' });
      this.popup.fadeIn(300, () => {
        this.isOpen = true;
        console.log('>> popup - open');
      });
      return;
    }
    const url = $(e.target).data('image');
    const image = `<img class="popup--image" src="${url}" alt="popup" />`;
    holder.append(image);
    holder.css({ padding: '' });
    this.popup.fadeIn(300, () => {
      this.isOpen = true;
      console.log('>> popup - open');
    });
    return;
  }
}
