import $ from 'jquery';

export default class Collaborations {
  constructor(
    filter = '.filter-anchor',
    root = '._filter',
    popup = '#collab-popup'
  ) {
    this.filterAnchor = $(filter);
    this.root = $(root);
    this.allItems = $('.collab-item');
    this.popupRoot = $(popup);
  }

  filter() {
    console.log(
      ' >> filter - collaboration %c[STARTED]',
      'background: #222; color: #bada55'
    );
    this.filterAnchor.on('click', e => {
      this.filterAnchor.each((i, val) => {
        if (val !== e.target) $(val).removeClass('active');
      });

      $(e.target).toggleClass('active');
      const checkActive = $(e.target).hasClass('active');

      if (!checkActive) {
        this.root.fadeOut(300, () => {
          this.root.empty();
          this.root.append([...this.allItems]);
          this.root.fadeIn(300);
          this.collabPopupInit();
        });

        return;
      }
      const filterType = $(e.target).data('filter');

      const allToFilter = [...this.allItems];
      const filtered = allToFilter.filter(
        val => $(val).data('type') === filterType
      );
      this.root.fadeOut(300, () => {
        this.root.empty();
        this.root.append(filtered);
        this.root.fadeIn(300);
        this.collabPopupInit();
      });
    });
  }

  collabPopupInit() {
    this.popupRoot.css({ display: 'none' });

    this.allItems.on('click', e => {
      const v = $(e.target);
      const image = v.data('image');
      const text = v.data('text');
      const type = v.data('type');
      const title = v.find('h3');
      const address = v.data('address');
      const name = v.data('name');
      const caption = v.data('caption');
      const siteUrl = v.data('website') ?? '';
      const instagram = v.data('instagram') ?? '';

      if (!address)
        this.popupRoot.find('#collab-address').css({ display: 'none' });
      else {
        const collabAdress = this.popupRoot.find('#collab-address');

        collabAdress.find('p').text(name);
        collabAdress.find('li').text(address);
        collabAdress.css({ display: 'block' });
      }

      if( siteUrl !== '' ) {
          this.popupRoot.find('.js-sitleurl').show().attr('href', siteUrl );
      }else {
        this.popupRoot.find('.js-sitleurl').attr('href', "#" ).hide();
      }

      if( instagram !== '' ) {
        this.popupRoot.find('.js-instagram').show().attr('href', instagram );
      }else {
        this.popupRoot.find('.js-instagram').attr('href', "#" ).hide();
      }

      if( instagram == '' && siteUrl == '' ) {
          this.popupRoot.find('.links__holder').hide();
      }else {
        this.popupRoot.find('.links__holder').show();
      }

      this.popupRoot.find('#collab-type').text(type);
      this.popupRoot.find('h3').text(title.text());
      const imageRoot = this.popupRoot.find('#collab-img');
      imageRoot.css({ opacity: 0 });
      imageRoot.attr('src', image);
      imageRoot.animate({ opacity: 1 }, 50);
      this.popupRoot.find('#collab-text').text(text);
      $(document.body).css({ overflowY: 'hidden' });
      this.popupRoot.fadeIn();

      this.popupRoot.on('click', e => {
        this.popupRoot.fadeOut(300, () => {
          $(document.body).css({ overflowY: 'auto' });
        });
		this.resetPopupBio();
      });
    });
  }

  resetPopupBio() {
	this.popupRoot.find('.text').animate({scrollTop:0}, 100);
	console.log(this.popupRoot.find('.text'))
  }
}
