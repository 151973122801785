import $ from 'jquery';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.config({ nullTargetWarn: false });
gsap.registerPlugin(ScrollTrigger);
export default class Marquee {
  init() {
    gsap.utils.toArray('.quote-block__section').forEach((section, index) => {
      const w = section.querySelector('.quote-block__text-wrapper');
      const [x, xEnd] =
        index % 2
          ? ['100%', (w.scrollWidth - section.offsetWidth) * -1]
          : [w.scrollWidth * -1, 0];
      gsap.fromTo(
        w,
        {
          x,
        },
        {
          x: xEnd,
          scrollTrigger: {
            trigger: section,
            toggleActions: 'restart pause reverse pause',
            scrub: 1,
          },
          //end: "+=500",
          duration: 5,
        }
      );
    });
  }
}
