import $ from 'jquery';

class Animate {
  constructor(el, settings) {
    this.el = el;
    this.animateClass = settings.animateClassName;
    this.offsetPos = settings.offset;
    this.init();
  }

  init() {
    var $animation_elements = $(this.el),
      $window = $(window),
      _this = this;

    function check_if_in_view() {
      var window_height = $window.height(),
        window_top_position = $window.scrollTop(),
        window_bottom_position = window_top_position + window_height;

      $.each($animation_elements, function () {
        var $element = $(this),
          element_height = $element.outerHeight(),
          element_top_position = $element.offset().top + _this.offsetPos,
          element_bottom_position = element_top_position + element_height;

        if (element_top_position <= window_bottom_position) {
          $element.addClass(_this.animateClass);
        } else {
          $element.removeClass(_this.animateClass);
        }
      });
    }

    $window.on('scroll resize', check_if_in_view);
    $window.on('click', () => {
      setTimeout(() => check_if_in_view(), 600);
    });
    $window.trigger('scroll');
  }
}

export default Animate;
