import $ from 'jquery';

$(() => {
    const windowScreen = $(window);
    const quickButton = $('.quickScrollTotop');
    let windowScreenHeight = windowScreen.outerHeight();

    windowScreen.on('scroll resize', () => {
        let windowScrollTop = windowScreen.scrollTop();
        if (windowScrollTop >= windowScreenHeight) {
            quickButton.fadeIn();
        } else {
            quickButton.fadeOut();
        }
    })


    quickButton.on('click', () => {
        $('html,body').animate({
            scrollTop: $('body').offset().top - 0
        }, 1000);
    })
})
