var touchState = {
	startX: -1,
	startY: -1,
	lastX: -1,
	lastY: -1,
};

function isiOS() {
	return (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
	);
}

function handler(e) {
	if (isiOS()) {
		const touch = e.touches[0];
		if (e.type === 'touchstart') {
			// save first touch position
			touchState.lastX = touchState.startX = touch.pageX;
			touchState.lastY = touchState.startY = touch.pageY;
		} else if (e.type === 'touchmove') {
			touchState.lastX = touch.pageX;
			touchState.lastY = touch.pageY;
		} else if (e.type === 'touchend') {
			// Detect if user taped button not swiped
			if (
				touchState.lastY === touchState.startY &&
				touchState.lastX === touchState.startX
			) {
				var link = e.target.closest('.project-hover');
				var url = link.getAttribute('data-href');
				if (url) {
					location.href = url;
				}
			}
		}
	}
}
document.querySelectorAll('.project-hover').forEach((item) => {
	item.ontouchstart = handler;
	item.ontouchmove = handler;
	item.ontouchend = handler;
});
