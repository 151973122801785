export default [
  {
    unitName: 201,
    generalInfo: '1,050 sf LOFT 1 BATH 220 sf balcony',
    price: '$2.500',
  },
  {
    unitName: 202,
    generalInfo: '834 sf LOFT 1 BATH 205 sf balcony',
    price: '$2.400',
  },
  {
    unitName: 203,
    generalInfo: '736 sf LOFT 1 BATH 208 sf balcony',
    price: '$3.100',
  },
  {
    unitName: 204,
    generalInfo: '1,073 sf LOFT 1 BATH 237 sf balcony',
    price: '$3.300',
  },
  {
    unitName: 301,
    generalInfo: '1,050 sf LOFT 1 BATH 220 sf balcony',
    price: '$2.500',
  },
  {
    unitName: 302,
    generalInfo: '834 sf LOFT 1 BATH 205 sf balcony',
    price: '$2.400',
  },
  {
    unitName: 303,
    generalInfo: '736 sf LOFT 1 BATH 208 sf balcony',
    price: '$3.100',
  },
  {
    unitName: 304,
    generalInfo: '1,073 sf LOFT 1 BATH 237 sf balcony',
    price: '$3.300',
  },
  {
    unitName: 401,
    generalInfo: '1,050 sf LOFT 1 BATH 220 sf balcony',
    price: '$2.500',
  },
  {
    unitName: 402,
    generalInfo: '834 sf LOFT 1 BATH 205 sf balcony',
    price: '$2.400',
  },
  {
    unitName: 403,
    generalInfo: '736 sf LOFT 1 BATH 208 sf balcony',
    price: '$3.100',
  },
  {
    unitName: 404,
    generalInfo: '1,073 sf LOFT 1 BATH 237 sf balcony',
    price: '$3.300',
  },
];
