import $ from 'jquery';

export const initQueries = query => {
  if (location.pathname !== query) {
    console.error(
      `>> WRONG PATH NAME! ${query} should be ${location.pathname}`
    );
    return;
  }
  console.log('>> QUERY CHECK PASSED');

  const getQuery = new URLSearchParams(location.search);
  if (getQuery.get('filter') === 'creator') {
    $('.filter-anchor.creator').trigger('click');
  } else if (getQuery.get('filter') === 'retailer') {
    $('.filter-anchor.retailer').trigger('click');
  } else if (getQuery.get('filter') === 'advisor') {
    $('.filter-anchor.advisor').trigger('click');
  }

  const allCollaborators = $('.collab-item');
  allCollaborators.each((i, val) => {
    const anchorId = $(val).data('anchor-id');
    if (getQuery.get('collab') === anchorId) {
      $(val).trigger('click');
    }
  });
};
