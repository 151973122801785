import $ from 'jquery';
import './modules/_lazyload';
import './modules/_quickScroll';
import './functions/_doubleTab';
import Header from './modules/_header';
import Projects from './pages/_projects';
import Marquee from './modules/_scrollAnimation';
import SlideBox from './components/_slide-box';
import Popup from './components/_popup';
import Collage from './components/_collage';
import data from './pages/_data';
import Animate from './modules/_animation';
import Parallax from './modules/_parallax';
import Collab from './pages/_collaborations';
import About from './pages/_about';
import Slider from './modules/_slider';
import Floorplan from './components/_floorplan-carousel';
import { initQueries } from './modules/_filterSlected';

$(() => {
	const header = new Header();
	const project = new Projects('#lightbox');
	const slideBox = new SlideBox('#box-slide', '.box-text').init();
	const popup = new Popup('#popup', data).init();
	const collage = new Collage('#collage', '#collage-anchor').init();
	const collab = new Collab();
	const about = new About('.about-anchor').init();
	const nextPrevLinks = $('.sticky-anchor');
	new Floorplan('.floorplan-anchor', '.floorplan-carousel').init();

	//init header
	header.init();

	if ($(window).width() > 992) {
		header.isSticky();
	} else {
		header.isFilled();
	}

	$('.link-href').on('click', (e) => {
		const t = $(e.target);
		const url = t.data('url');

		window.location.href = url;
	});

	nextPrevLinks.css({
		position: 'absolute',
		top: `${window.innerHeight / 2 + nextPrevLinks.innerWidth() / 2}px`,
	});

	$(window).on('resize', () => {
		nextPrevLinks.css({
			position: 'absolute',
			top: `${window.innerHeight / 2 + nextPrevLinks.innerWidth() / 2}px`,
		});
	});

	$(window).on('scroll', () => {
		const loc = $(window).scrollTop();

		if (loc > 0) {
			nextPrevLinks.css({
				position: 'fixed',
				top: `${
					window.innerHeight / 2 + nextPrevLinks.innerWidth() / 2
				}px`,
			});
		} else {
			nextPrevLinks.css({
				position: 'absolute',
				top: `${
					window.innerHeight / 2 + nextPrevLinks.innerWidth() / 2
				}px`,
			});
		}
	});

	project.init();

	//init scroll animation
	new Animate('.animate', {
		animateClassName: 'in-view',
		offset: 50,
	});

	collab.filter();
	collab.collabPopupInit();
	initQueries('/collaborations');

	if ($('.quote-block--marquee').length & ($(window).width() > 992)) {
		var marquee = new Marquee();
		marquee.init();
	}

	var parallax = new Parallax();
	parallax.init();

	//init hero slider
	var heroSlider = new Slider('#hero-slider', {
		slidesPerView: 1,
		spaceBetween: 10,
		fadeEffect: {
			crossFade: true,
		},
		effect: 'fade',
		loop: true,
		reverseDirection: false,

		navigation: {
			nextEl: '.hero__cursor--right',
			prevEl: '.hero__cursor--left',
		},
	});

	//smooth scroll
	$('a[href*="#"]:not([href="#"])').click(function () {
		if (
			location.pathname.replace(/^\//, '') ==
				this.pathname.replace(/^\//, '') &&
			location.hostname == this.hostname
		) {
			var target = $(this.hash);
			target = target.length
				? target
				: $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html,body').animate(
					{
						scrollTop: target.offset().top - 0,
					},
					1000
				);
				return false;
			}
		}
	});

	const centerHeroCaption = () => {
		var heroCaption = $('.hero__content-img-caption');

		heroCaption.each(function () {
			var heroCaptionHeight = $(this).outerWidth();
			$(this).css({
				marginTop: '-' + heroCaptionHeight + 'px',
			});
		});
	};

	centerHeroCaption();

	$(window).on('resize', centerHeroCaption);
});
