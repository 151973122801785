import Swiper, {
    Navigation,
    Pagination,
    EffectFade
} from 'swiper/core';
import SlideBox from '../components/_slide-box';
import LazyLoad from 'vanilla-lazyload';


Swiper.use([Navigation, Pagination, EffectFade]);

export default class Slider {
    constructor(el, settings) {
        this.el = el;
        this.settings = settings;
        this.init();
    }

    init() {
        var lazyImg = new LazyLoad(),
            defaultSettings = {
                observeParents: true,
                observer: true,
                on: {
                    init: false,
                    afterInit: function () {
                        var lazyImage = this.$el.find('img');
                        lazyImg.loadAll(lazyImage);
                    }
                }
            },
            getSettings = this.settings,
            fullSettings = Object.assign(getSettings, defaultSettings);
        new Swiper(this.el, fullSettings);
    }
}