import { default as $, default as jQuery } from 'jquery';

$.easing.jswing = $.easing.swing;

$.extend($.easing, {
  def: 'easeOutQuad',
  swing: function (x, t, b, c, d) {
    //alert($.easing.default);
    return $.easing[$.easing.def](x, t, b, c, d);
  },
  easeInQuad: function (x, t, b, c, d) {
    return c * (t /= d) * t + b;
  },
  easeOutQuad: function (x, t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  },
  easeInOutQuad: function (x, t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
    return (-c / 2) * (--t * (t - 2) - 1) + b;
  },
});

export default class Collage {
  constructor(el, anchor) {
    this.target = $(el);
    this.anchor = $(anchor);

    this.isOpen = false;
  }

  init() {
    this.target.css({ display: 'none' });
    const overlay = this.target.find('.collage__item--overlay');

    const directions = ['top', 'right', 'bottom', 'left'];

    console.log('>> collage - loaded');
    if (!this.isOpen) {
      this.isOpen = true;
      this.anchor.on('click', () => {
        this.target.fadeIn(500, () => {
          overlay.each((i, val) => {
            const randomDirection = Math.floor(
              Math.random() * directions.length
            );
            const randomTimer = Math.floor(Math.random() * 1000) + 500;

            $(val).animate(
              {
                [directions[randomDirection]]: '-100%',
              },
              randomTimer,
              'easeOutCirc'
            );
          });
        });
      });
    }
    this.target.on('click', () => {
      console.log('close');
      this.target.fadeOut(300, () => {
        directions.forEach(val => {
          overlay.css({ [val]: '' });
        });
        this.isOpen = false;
      });
    });
  }
}
