import $ from 'jquery';
import waitForImages from 'jquery.waitforimages';
import Header from '../modules/_header';
import Marquee from '../modules/_scrollAnimation';


export default class Parallax {
    init() {


        if ($('.landing').length) {
            $('.landing__text').fadeIn();
        }

        this.afterLoadedImg();
    }



    afterLoadedImg() {
        var header = new Header(),
            marquee = new Marquee();

        $(window).on('beforeunload', () => {
            $('.landing').animate({
                scrollTop: 0
            }, 100);
        });

        $('.landing img').waitForImages(function () {
            setTimeout(() => {
                $('.landing').fadeOut();
                $('.landing + .wrapper').fadeIn(() => {
                    $('html,body').animate({
                            scrollTop: $('html, body').offset().top - 0,
                        },
                        1000,
                        () => {
                            header.isSticky();
                            if ($(window).width() > 992) {
                                marquee.init();
                            }
                            
                            const centerHeroCaption = () => {
                                var heroCaption = $('.hero__content-img-caption');

                                heroCaption.each(function () {
                                    var heroCaptionHeight = $(this).outerWidth();
                                    $(this).css({
                                        marginTop: '-' + heroCaptionHeight + 'px',
                                    });
                                });
                            };

                            centerHeroCaption();

                            $(window).on('resize', centerHeroCaption);
                        }
                    );
                });
            }, 1500)
        });



    }
}