import $ from 'jquery';
import 'jquery-ui/ui/effect';
import 'jquery-ui/ui/effects/effect-fade';
export default class SlideBox {
  constructor(target, textTarget, speed = 500, timeout = 750) {
    this.target = $(target);
    this.textTarget = [...$(textTarget)];
    this.speed = speed;
    this.timeout = timeout;
    this.targetWidth = this.target.innerWidth();
  }

  init() {
    if (this.target.length) {
      const width = this.targetWidth.toString();
      this.target.css({ right: `-${width}px` });
      this.textTarget.forEach(val => {
        $(val).css({ opacity: 0 });
      });
      setTimeout(() => {
        this.target.animate(
          {
            right: `0px`,
          },
          this.speed,
          () => {
            this.textTarget.forEach(val => {
              $(val).animate({ opacity: 1 }, 500);
            });
          }
        );
      }, this.timeout);
    }
  }
}
