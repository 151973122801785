import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

// SwiperCore.use([Navigation, Pagination]);
export default class Floorplans {
  constructor(anchor, el, slider = '.floorplan-slick') {
    this.anchor = $(anchor);
    this.forSlider = slider;
    this.el = $(el);
    this.slider = $(slider);
    this.sliderWrapper = $(slider).find('.swiper-wrapper');
    this.isOpen = false;
    this.close = $('.floorplan-close');
  }

  init() {
    console.log('>> floorplan - initalized');
    this.el.css({ display: 'none' });
    const imageUrls = this.anchor.data('images');
    if (imageUrls) {
      const urls = imageUrls.split(',');
      this.sliderWrapper.empty();
      urls.forEach(val => {
        const imgElement = document.createElement('img');
        const img = $(imgElement);
        img.attr('src', val);
        img.addClass('swiper-slide');
        this.sliderWrapper.append(img);
      });
    }

    const floorplanSlider = new Swiper('.floorplan-slick', {
      speed: 400,
      cssMode: true,
      slidesPerView: 1,
      spaceBetween: 150,
      loop: true,
      observer: true,
      observeParents: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    this.anchor.on('click', e => {
      console.log('test for click!');
      this.el.fadeIn();
      this.close.on('click', () => {
        this.el.fadeOut();
      });
    });
  }
}
