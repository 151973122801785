import $, { nodeName } from 'jquery';

export default class Projects {
	constructor() {
		this.helloWorld = 'Hello World';
		//this.Lightbox = new Lightbox('#lightbox');
		this.galleryHolder = $('.projects__gallery');
		this.galleryImg = $('#js-project-img');
		this.isOpen = false;
		this.preloadImages();
	}

	init() {
		console.log('>> projects - loaded');
		//this.Lightbox.init();
		const links = $('.project-hover');
		links.each((i, val) => {
			$(val)
				.on('mouseenter', (e) => {
					const target = $(e.currentTarget),
						image = target.data('image-url'),
						href = target.data('href');

					this.galleryHolder.fadeOut(200, () => {
						this.galleryImg.attr('src', image);
						this.galleryHolder.fadeIn(200);
					});
					target.on('click', () => (window.location.href = href));
				})
				.on('mouseleave', () => {
					this.galleryHolder.fadeOut('200');
				});
		});
	}

	preloadImages() {
		var ImgHolder = $('.project-hover'),
			imgArray = [];

		ImgHolder.each((i, el) => {
			var img = $(el).attr('data-image-url');
			imgArray.push(img);
		});

		this.setPreloadUrl(imgArray);
	}

	setPreloadUrl(urls) {
		urls.forEach((i) => {
			const linkEl = document.createElement('link');
			linkEl.setAttribute('rel', 'preload');
			linkEl.setAttribute('href', i);
			linkEl.setAttribute('as', 'image');
			document.head.appendChild(linkEl);
			console.log('preloaded Image');
		});
	}
}
